import { AdaptiveDpr, Environment, Html } from "@react-three/drei";
import React, { Suspense } from "react";
import Wado from "./Wado";

export const WadoScene: React.FC = () => {
  return (
    <group name="wado-scene">
      {/* <Stats /> */}
      <Suspense fallback={<Html>Loading...</Html>}>
        <Wado position={[-1, -5, 0]} rotation={[0, Math.PI * 0.45, 0]} />

        <Environment preset="forest" />
      </Suspense>

      <AdaptiveDpr pixelated />
    </group>
  );
};
