import React from "react";

interface Data {
  imageUrl: string;
  title: string;
  body: string;
}

const contentData: Data[] = [
  {
    imageUrl: "images/whatwedo_01_droneshooting.jpg",
    title: "空撮映像",
    body: "日本の各地を巡り、絶景の映像を撮影",
  },
  {
    imageUrl: "images/whatwedo_02_photogrametry.jpg",
    title: "フォトグラメトリ",
    body: "UAVで撮影した写真を解析し3次元データを作成",
  },
  {
    imageUrl: "images/whatwedo_03_modelling.jpg",
    title: "3Dモデリング",
    body: "3次元データを加工し最適化",
  },
  {
    imageUrl: "images/whatwedo_04_3dprinting.jpg",
    title: "3Dプリント",
    body: "高品質な3Dプリントを代行",
  },
];

const Content: React.FC<Data> = ({ imageUrl, title, body }) => {
  return (
    <div className="flex flex-col gap-4 py-4 sm:py-0">
      <img
        src={imageUrl}
        alt={title}
        className="rounded-lg sm:h-80 sm:object-cover"
      />
      <div>
        <h1 className="text-lg font-semibold">{title}</h1>
        <p>{body}</p>
      </div>
    </div>
  );
};

export const WhatWeDo = () => {
  return (
    <div id="whatwedo" className="w-full px-6 py-10 bg-yellow-400">
      <h1 className="mb-5 text-2xl font-bold">サービス内容</h1>
      <div className="grid grid-cols-1 sm:gap-10 md:grid-cols-2 xl:grid-cols-4">
        {contentData.map((data, i) => {
          return (
            <Content
              key={`${data.title}-${i}`}
              imageUrl={data.imageUrl}
              title={data.title}
              body={data.body}
            />
          );
        })}
      </div>
    </div>
  );
};
