// import { useState } from "react";

export const ContactUs = () => {
  // const [name, setName] = useState<string>("");
  // const [email, setEmail] = useState<string>("");
  // const [message, setMessage] = useState<string>("");

  return (
    <div
      id="contactus"
      className="flex items-center w-full px-12 py-10 text-gray-800 bg-yellow-400"
    >
      <span className="pb-4 mx-auto text-lg sm:text-2xl">
        お問い合わせ先は{" "}
        <a
          className="px-6 font-bold transition-colors duration-500 hover:text-blue-500"
          href={`mailto:dippiesofficial@gmail.com`}
        >
          dippiesofficial@gmail.com
        </a>
        か下記のSNSからお願いします
      </span>
    </div>
  );

  // return (
  //   <div className="px-12 py-10 bg-yellow-400">
  //     <h1 className="pb-4 text-2xl">Contact Us</h1>

  //     <form
  //       className="flex flex-col w-full p-4 bg-gray-300 rounded-lg"
  //       onSubmit={() => alert("submit logic here")}
  //     >
  //       <p>Name</p>
  //       <input
  //         value={name}
  //         onChange={(e) => setName(e.target.value)}
  //         type="text"
  //       />

  //       <p>E-mail</p>
  //       <input
  //         value={email}
  //         onChange={(e) => setEmail(e.target.value)}
  //         type="email"
  //       />

  //       <p>Message</p>
  //       <textarea
  //         value={message}
  //         onChange={(e) => setMessage(e.target.value)}
  //         rows={5}
  //       ></textarea>

  //       <br />
  //       <input type="submit" value="Send" />
  //     </form>
  //   </div>
  // );
};
