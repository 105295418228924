export const TopSection = () => {
  return (
    <div className="relative w-full h-screen">
      <img
        className="object-cover w-full h-full"
        src="images/top_fuji.jpg"
        alt=""
      />

      <div className="absolute w-full px-6 py-6 text-center md:px-40 bottom-10 sm:bottom-40 text-gray-50">
        <h1 className="mb-5 text-3xl font-medium sm:mb-14 sm:text-5xl">
          ありのままの世界を写す
        </h1>

        <p className="text-lg font-thin sm:text-2xl">
          「写し」=『そのまま真似するのではなく、元よりももっと良いものにすること』
          世界中にある自然物、文化財を3Dデータとして記録、保存していきます。
        </p>
      </div>
    </div>
  );
};
