import "@brainhubeu/react-carousel/lib/style.css";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import useScrollLock from "use-scroll-lock";
import { Project } from ".";
import { CarouselView } from "./Carousel";
import { ExpandedView } from "./Expanded";

const DEFAULTS = {
  expanded: false,
  index: 0,
};

export const CarouselContainer: React.FC<{ data: Project[] }> = ({ data }) => {
  const [expanded, setExpanded] = useState(DEFAULTS.expanded);

  const [carouselIndex, setCarouselIndex] = useState(DEFAULTS.index);
  const handleCarouselChange = (newValue: number) => setCarouselIndex(newValue);
  const handleExpand = () => setExpanded(true);

  const scrollableRef = useRef<HTMLDivElement>(null);
  useScrollLock(expanded, scrollableRef); // true = scroll locked

  const projectData = data[carouselIndex];
  return (
    <div>
      <div style={{ display: expanded ? "block" : "none" }}>
        <ExpandedView
          key="carousel-expanded"
          onClose={() => setExpanded(false)}
          projectData={projectData}
          scrollableRef={scrollableRef}
          expanded={expanded}
        />
      </div>
      <div style={{ display: expanded ? "none" : "block" }}>
        <CarouselView
          key="carousel"
          carouselData={data}
          onCarouselNavigationChange={handleCarouselChange}
          carouselIndex={carouselIndex}
          onExpand={handleExpand}
        />
      </div>
    </div>
  );
};
