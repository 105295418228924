/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    odm_textured_model_geo_1: THREE.Mesh;
    odm_textured_model_geo_2: THREE.Mesh;
    odm_textured_model_geo_3: THREE.Mesh;
    odm_textured_model_geo_4: THREE.Mesh;
    odm_textured_model_geo_5: THREE.Mesh;
    odm_textured_model_geo_6: THREE.Mesh;
    odm_textured_model_geo_7: THREE.Mesh;
    odm_textured_model_geo_8: THREE.Mesh;
    odm_textured_model_geo_9: THREE.Mesh;
    odm_textured_model_geo_10: THREE.Mesh;
    odm_textured_model_geo_11: THREE.Mesh;
    odm_textured_model_geo_12: THREE.Mesh;
    odm_textured_model_geo_13: THREE.Mesh;
    odm_textured_model_geo_14: THREE.Mesh;
    odm_textured_model_geo_15: THREE.Mesh;
    odm_textured_model_geo_16: THREE.Mesh;
    odm_textured_model_geo_17: THREE.Mesh;
    odm_textured_model_geo_18: THREE.Mesh;
    odm_textured_model_geo_19: THREE.Mesh;
    odm_textured_model_geo_20: THREE.Mesh;
    odm_textured_model_geo_21: THREE.Mesh;
    odm_textured_model_geo_22: THREE.Mesh;
    odm_textured_model_geo_23: THREE.Mesh;
    odm_textured_model_geo_24: THREE.Mesh;
    odm_textured_model_geo_25: THREE.Mesh;
    odm_textured_model_geo_26: THREE.Mesh;
    odm_textured_model_geo_27: THREE.Mesh;
    odm_textured_model_geo_28: THREE.Mesh;
    odm_textured_model_geo_29: THREE.Mesh;
    odm_textured_model_geo_30: THREE.Mesh;
    odm_textured_model_geo_31: THREE.Mesh;
    odm_textured_model_geo_32: THREE.Mesh;
    odm_textured_model_geo_33: THREE.Mesh;
    odm_textured_model_geo_34: THREE.Mesh;
    odm_textured_model_geo_35: THREE.Mesh;
    odm_textured_model_geo_36: THREE.Mesh;
    odm_textured_model_geo_37: THREE.Mesh;
    odm_textured_model_geo_38: THREE.Mesh;
    odm_textured_model_geo_39: THREE.Mesh;
    odm_textured_model_geo_40: THREE.Mesh;
    odm_textured_model_geo_41: THREE.Mesh;
    odm_textured_model_geo_42: THREE.Mesh;
    odm_textured_model_geo_43: THREE.Mesh;
    odm_textured_model_geo_44: THREE.Mesh;
    odm_textured_model_geo_45: THREE.Mesh;
    odm_textured_model_geo_46: THREE.Mesh;
  };
  materials: {
    material0000: THREE.MeshStandardMaterial;
    material0001: THREE.MeshStandardMaterial;
    material0002: THREE.MeshStandardMaterial;
    material0003: THREE.MeshStandardMaterial;
    material0004: THREE.MeshStandardMaterial;
    material0005: THREE.MeshStandardMaterial;
    material0006: THREE.MeshStandardMaterial;
    material0007: THREE.MeshStandardMaterial;
    material0008: THREE.MeshStandardMaterial;
    material0009: THREE.MeshStandardMaterial;
    material0010: THREE.MeshStandardMaterial;
    material0011: THREE.MeshStandardMaterial;
    material0012: THREE.MeshStandardMaterial;
    material0013: THREE.MeshStandardMaterial;
    material0014: THREE.MeshStandardMaterial;
    material0015: THREE.MeshStandardMaterial;
    material0016: THREE.MeshStandardMaterial;
    material0017: THREE.MeshStandardMaterial;
    material0018: THREE.MeshStandardMaterial;
    material0019: THREE.MeshStandardMaterial;
    material0020: THREE.MeshStandardMaterial;
    material0021: THREE.MeshStandardMaterial;
    material0022: THREE.MeshStandardMaterial;
    material0023: THREE.MeshStandardMaterial;
    material0024: THREE.MeshStandardMaterial;
    material0025: THREE.MeshStandardMaterial;
    material0026: THREE.MeshStandardMaterial;
    material0027: THREE.MeshStandardMaterial;
    material0028: THREE.MeshStandardMaterial;
    material0029: THREE.MeshStandardMaterial;
    material0030: THREE.MeshStandardMaterial;
    material0031: THREE.MeshStandardMaterial;
    material0032: THREE.MeshStandardMaterial;
    material0033: THREE.MeshStandardMaterial;
    material0034: THREE.MeshStandardMaterial;
    material0035: THREE.MeshStandardMaterial;
    material0036: THREE.MeshStandardMaterial;
    material0037: THREE.MeshStandardMaterial;
    material0038: THREE.MeshStandardMaterial;
    material0039: THREE.MeshStandardMaterial;
    material0040: THREE.MeshStandardMaterial;
    material0041: THREE.MeshStandardMaterial;
    material0042: THREE.MeshStandardMaterial;
    material0043: THREE.MeshStandardMaterial;
    material0044: THREE.MeshStandardMaterial;
    material0045: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/works/ongoku/ongoku_v21.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.0016999, 0.002129, 0.0050651]} scale={0.0007634}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_1.geometry}
          material={materials.material0000}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_2.geometry}
          material={materials.material0001}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_3.geometry}
          material={materials.material0002}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_4.geometry}
          material={materials.material0003}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_5.geometry}
          material={materials.material0004}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_6.geometry}
          material={materials.material0005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_7.geometry}
          material={materials.material0006}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_8.geometry}
          material={materials.material0007}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_9.geometry}
          material={materials.material0008}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_10.geometry}
          material={materials.material0009}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_11.geometry}
          material={materials.material0010}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_12.geometry}
          material={materials.material0011}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_13.geometry}
          material={materials.material0012}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_14.geometry}
          material={materials.material0013}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_15.geometry}
          material={materials.material0014}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_16.geometry}
          material={materials.material0015}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_17.geometry}
          material={materials.material0016}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_18.geometry}
          material={materials.material0017}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_19.geometry}
          material={materials.material0018}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_20.geometry}
          material={materials.material0019}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_21.geometry}
          material={materials.material0020}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_22.geometry}
          material={materials.material0021}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_23.geometry}
          material={materials.material0022}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_24.geometry}
          material={materials.material0023}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_25.geometry}
          material={materials.material0024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_26.geometry}
          material={materials.material0025}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_27.geometry}
          material={materials.material0026}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_28.geometry}
          material={materials.material0027}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_29.geometry}
          material={materials.material0028}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_30.geometry}
          material={materials.material0029}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_31.geometry}
          material={materials.material0030}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_32.geometry}
          material={materials.material0031}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_33.geometry}
          material={materials.material0032}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_34.geometry}
          material={materials.material0033}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_35.geometry}
          material={materials.material0034}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_36.geometry}
          material={materials.material0035}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_37.geometry}
          material={materials.material0036}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_38.geometry}
          material={materials.material0037}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_39.geometry}
          material={materials.material0038}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_40.geometry}
          material={materials.material0039}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_41.geometry}
          material={materials.material0040}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_42.geometry}
          material={materials.material0041}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_43.geometry}
          material={materials.material0042}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_44.geometry}
          material={materials.material0043}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_45.geometry}
          material={materials.material0044}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_46.geometry}
          material={materials.material0045}
        />
      </group>
    </group>
  );
}
