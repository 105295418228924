/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    odm_textured_model_geo_1: THREE.Mesh;
    odm_textured_model_geo_2: THREE.Mesh;
    odm_textured_model_geo_3: THREE.Mesh;
    odm_textured_model_geo_4: THREE.Mesh;
    odm_textured_model_geo_5: THREE.Mesh;
    odm_textured_model_geo_6: THREE.Mesh;
    odm_textured_model_geo_7: THREE.Mesh;
    odm_textured_model_geo_8: THREE.Mesh;
    odm_textured_model_geo_9: THREE.Mesh;
    odm_textured_model_geo_10: THREE.Mesh;
    odm_textured_model_geo_11: THREE.Mesh;
  };
  materials: {
    material0000: THREE.MeshStandardMaterial;
    material0001: THREE.MeshStandardMaterial;
    material0002: THREE.MeshStandardMaterial;
    material0003: THREE.MeshStandardMaterial;
    material0004: THREE.MeshStandardMaterial;
    material0005: THREE.MeshStandardMaterial;
    material0006: THREE.MeshStandardMaterial;
    material0007: THREE.MeshStandardMaterial;
    material0008: THREE.MeshStandardMaterial;
    material0009: THREE.MeshStandardMaterial;
    material0010: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/works/wado-ruin/wado_coin.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[0, 8.5389957, 2.2190647]}
        rotation={[-0.0896817, 0.2716254, 0.1547757]}
        scale={[3, 3, 3]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_1.geometry}
          material={materials.material0000}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_2.geometry}
          material={materials.material0001}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_3.geometry}
          material={materials.material0002}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_4.geometry}
          material={materials.material0003}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_5.geometry}
          material={materials.material0004}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_6.geometry}
          material={materials.material0005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_7.geometry}
          material={materials.material0006}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_8.geometry}
          material={materials.material0007}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_9.geometry}
          material={materials.material0008}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_10.geometry}
          material={materials.material0009}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo_11.geometry}
          material={materials.material0010}
        />
      </group>
    </group>
  );
}
