import React from "react";
import { ReactComponent as CrossIcon } from "../../cross-sign.svg";

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="absolute z-50 p-4 transition-colors bg-yellow-400 rounded-full top-4 right-4 hover:bg-gray-900 hover:text-white"
      onClick={onClick}
    >
      <CrossIcon className="w-3 h-3" />
    </button>
  );
};
