/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    odm_textured_model_geo001_1: THREE.Mesh;
    odm_textured_model_geo001_2: THREE.Mesh;
    odm_textured_model_geo001_3: THREE.Mesh;
    odm_textured_model_geo001_4: THREE.Mesh;
    odm_textured_model_geo001_5: THREE.Mesh;
    odm_textured_model_geo001_6: THREE.Mesh;
    odm_textured_model_geo001_7: THREE.Mesh;
    odm_textured_model_geo001_8: THREE.Mesh;
    odm_textured_model_geo001_9: THREE.Mesh;
    odm_textured_model_geo001_10: THREE.Mesh;
  };
  materials: {
    ["material0000.001"]: THREE.MeshStandardMaterial;
    ["material0001.001"]: THREE.MeshStandardMaterial;
    ["material0002.001"]: THREE.MeshStandardMaterial;
    ["material0003.001"]: THREE.MeshStandardMaterial;
    ["material0004.001"]: THREE.MeshStandardMaterial;
    ["material0005.001"]: THREE.MeshStandardMaterial;
    ["material0006.001"]: THREE.MeshStandardMaterial;
    ["material0007.001"]: THREE.MeshStandardMaterial;
    ["material0008.001"]: THREE.MeshStandardMaterial;
    ["material0009.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/works/terasaka-rice/terasaka_d.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[-3.6255331, 29.1460743, 11.7697563]}
        rotation={[3.0913682, 0, 0]}
        scale={[0.1016434, 0.1016434, 0.1016434]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_1.geometry}
          material={materials["material0000.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_2.geometry}
          material={materials["material0001.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_3.geometry}
          material={materials["material0002.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_4.geometry}
          material={materials["material0003.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_5.geometry}
          material={materials["material0004.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_6.geometry}
          material={materials["material0005.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_7.geometry}
          material={materials["material0006.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_8.geometry}
          material={materials["material0007.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_9.geometry}
          material={materials["material0008.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model_geo001_10.geometry}
          material={materials["material0009.001"]}
        />
      </group>
    </group>
  );
}
