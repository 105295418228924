import React from "react";
import { Reflector } from "@react-three/drei";
import { Vector3 } from "@react-three/fiber";

const Sea = ({ isLowQuality = false, position = [0, 0, 0] }) => {
  //   const [floor] = useTexture(["wall_reflection.jpg"]);
  return (
    <Reflector
      resolution={isLowQuality ? 100 : 1024}
      args={[80, 80]}
      mirror={1}
      mixBlur={1}
      mixStrength={1}
      rotation={[-Math.PI / 2, 0, 0]}
      blur={[400, 100]}
      position={position as unknown as Vector3}
    >
      {(Material, props) => (
        <Material
          //   color="#a0a0a0"
          color="lightblue"
          roughness={0.5}
          //   roughnessMap={floor}
          // normalMap={normal}
          // normalScale={[1, 1] as unknown as THREE.Vector2}
          {...props}
        />
      )}
    </Reflector>
  );
};

export default Sea;
