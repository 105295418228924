import React from "react";
import { Project } from ".";

export const Item: React.FC<{
  project: Project;
  onClick: (model: JSX.Element | null) => void;
}> = ({ project, onClick }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-5 px-6 select-none">
      <img
        className="object-cover rounded-lg h-3/5"
        style={{ maxHeight: 800 }}
        alt={project.projectTitle}
        src={project.previewUrl}
      />
      <span className="text-xl font-semibold sm:text-3xl">
        {project.projectTitle}
      </span>
      <button
        onClick={() => onClick(project.model)}
        className="px-4 py-2 text-xs text-white transition-colors bg-yellow-700 rounded-full hover:bg-gray-900 sm:px-6 sm:py-2 sm:text-base hover:text-yellow-300"
      >
        {project.model ? "3Dスキャンを見る" : "3Dスキャンを見る"}
      </button>
    </div>
  );
};
