interface Data {
  imageUrl: string;
  name: string;
  description: string;
}

const memberData: Data[] = [
  {
    imageUrl: "images/abc_pic.jpg",
    name: "Shota Tatsunami",
    description: "3Dアーティスト・JUIDA操縦技能証明証保有者・安全運航管理者",
  },
  {
    imageUrl: "images/wafu_pic.jpg",
    name: "Fuyuki Watanabe",
    description: "ゲームエンジニア・JUIDA操縦技能証明証保有者・安全運航管理者",
  },
  {
    imageUrl: "images/qun_pic.jpg",
    name: "Li Qun Tang",
    description: "フロントエンドエンジニア・バックエンドエンジニア",
  },
];

const MemberCard: React.FC<Data> = ({ imageUrl, name, description }) => {
  return (
    <div className="flex flex-col items-center p-10">
      <div className="flex items-center justify-center w-40 h-40 overflow-hidden bg-gray-500 rounded-full">
        <img src={imageUrl} alt={name} className="object-cover w-full h-full" />
      </div>
      <h1 className="mt-5 text-xl font-semibold">{name}</h1>
      <p className="mt-2 text-center">{description}</p>
    </div>
  );
};

export const Members = () => {
  return (
    <div id="members" className="px-6 py-10 md:py-12">
      <h1 className="mb-5 text-2xl font-bold">メンバー</h1>

      <div className="flex flex-col items-center justify-center w-full lg:flex-row">
        {memberData.map((data, i) => {
          return (
            <MemberCard
              key={`${data.name}-${i}`}
              imageUrl={data.imageUrl}
              name={data.name}
              description={data.description}
            />
          );
        })}
      </div>
    </div>
  );
};
