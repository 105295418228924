import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";

interface BackdropProps {
  camera?: {
    position?: [number, number, number];
    fov?: number;
    zoom?: number;
  };
  scene?: {
    fog?: {
      near?: number;
      far?: number;
    };
  };
  controls?: {
    limit?: {
      left?: number;
      right?: number;
      up?: number;
      down?: number;
      near?: number;
      far?: number;
    };
  };
  pannable?: boolean;
}

const getAngle = (num: number) => Math.PI * num;
const neg = (num?: number) => (num ? -num : num!);
export const Backdrop: React.FC<BackdropProps> = ({
  children,
  camera,
  scene,
  controls,
  pannable,
}) => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Canvas
        shadows
        camera={{
          position: camera?.position || [0, 7, 12],
          fov: camera?.fov || 70,
          zoom: camera?.zoom,
        }}
      >
        {children}

        <OrbitControls
          enablePan={pannable || false}
          autoRotate
          autoRotateSpeed={0.05}
          maxAzimuthAngle={
            controls?.limit?.right
              ? getAngle(controls?.limit?.right)
              : undefined
          } // right
          minAzimuthAngle={
            controls?.limit?.left
              ? getAngle(neg(controls!.limit!.left))
              : undefined
          } // left
          minDistance={controls?.limit?.near || 5}
          maxDistance={controls?.limit?.far || 15}
          maxPolarAngle={getAngle(controls?.limit?.down || 0.4)} // down
          minPolarAngle={getAngle(controls?.limit?.up || 0.1)} // up
        />
        <color attach="background" args={["white"]} />
        <fog
          color={"white" as unknown as THREE.Color}
          attach="fog"
          near={scene?.fog?.near || 8}
          far={scene?.fog?.far || 30}
        />
      </Canvas>
    </div>
  );
};
