export const Pricing = () => {
  return (
    <div id="pricing" className="px-6 py-10 md:py-12">
      <h1 className="mb-5 text-2xl font-bold">料金プラン</h1>

      <div className="flex flex-col gap-4 p-8 mt-4 text-lg font-medium bg-gray-300 rounded-lg">
        <div className="flex justify-between">
          <h1>空撮映像</h1> <p>50,000 円</p>
        </div>

        <div className="flex justify-between">
          <h1>フォトグラメトリ</h1> <p>200,000 円</p>
        </div>

        <div className="flex justify-between">
          <h1>3Dモデリング</h1> <p>50,000 円</p>
        </div>

        <div className="flex justify-between">
          <h1>3Dプリント</h1> <p>50,000 円</p>
        </div>

        <div className="flex justify-between">
          <h1>パッケージ</h1> <p>要相談</p>
        </div>
      </div>
    </div>
  );
};
