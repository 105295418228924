import { AdaptiveDpr, Environment, Html } from "@react-three/drei";
import React, { Suspense } from "react";
import Ongoku from "./Ongoku";

export const OngokuScene: React.FC<{ isSea?: boolean }> = ({
  isSea = true,
}) => {
  return (
    <group name="island-scene">
      {/* <Stats /> */}
      <Suspense fallback={<Html>Loading...</Html>}>
        <Ongoku position={[-2, 0, 0]} rotation={[0, -Math.PI * 2.1, 0]} scale={80} />

        <Environment preset="forest" />
      </Suspense>

      <AdaptiveDpr pixelated />
    </group>
  );
};
