import React from "react";
import Carousel, { Dots, arrowsPlugin } from "@brainhubeu/react-carousel";
import { Item } from "./CarouselItem";
import { Project } from ".";

interface Props {
  carouselIndex: number;
  onCarouselNavigationChange: (value: number) => void;
  carouselData: Project[];
  onExpand: () => void;
}

export const CarouselView: React.FC<Props> = ({
  carouselIndex,
  onCarouselNavigationChange,
  carouselData,
  onExpand,
}) => {
  return (
    <>
      <Carousel
        className="md:h-carousel"
        value={carouselIndex}
        onChange={(e) => {
          if (!isNaN(e)) onCarouselNavigationChange(e);
        }}
        plugins={[
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: (
                <button className="outline-none">
                  <img
                    alt="arrow-left"
                    className="object-contain w-10 h-10 p-1 outline-none select-none opacity-90 filter invert"
                    src="/icons/fast-forward.png"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </button>
              ),
              arrowLeftDisabled: (
                <button className="outline-none">
                  <img
                    alt="arrow-left-disabled"
                    className="object-contain w-10 h-10 p-1 select-none opacity-10"
                    src="/icons/fast-forward.png"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </button>
              ),
              arrowRight: (
                <button>
                  <img
                    alt="arrow-right"
                    className="object-contain w-10 h-10 p-1 outline-none select-none opacity-90 filter invert"
                    src="/icons/fast-forward.png"
                  />
                </button>
              ),
              arrowRightDisabled: (
                <button>
                  <img
                    alt="arrow-right-disabled"
                    className="object-contain w-10 h-10 p-1 select-none opacity-10"
                    src="/icons/fast-forward.png"
                  />
                </button>
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
      >
        {carouselData.map((project, i) => {
          return (
            <Item key={`project-${i}`} project={project} onClick={onExpand} />
          );
        })}
      </Carousel>
      <br />
      <Dots
        value={carouselIndex}
        onChange={(e) => {
          if (!isNaN(e)) onCarouselNavigationChange(e);
        }}
        number={carouselData.length}
      />
    </>
  );
};
