const links = [
  {
    title: "youtube",
    imageUrl: "icons/youtube.svg",
    link: "https://www.youtube.com/channel/UC7KbbMVAkVLaQr_Qk8DOjrQ",
  },
  {
    title: "instagram",
    imageUrl: "icons/instagram.svg",
    link: "https://www.instagram.com/dippies_official/",
  },
  {
    title: "pinterest",
    imageUrl: "icons/pinterest.svg",
    link: "https://www.pinterest.jp/dippiebrothers/_created/",
  },
  {
    title: "hatena-blog",
    imageUrl: "icons/hatenablog-logo.svg",
    link: "https://dippies.hatenablog.com/",
  },
];

export const Footer = () => {
  return (
    <div className="flex flex-col items-center px-12 py-6 bg-yellow-400 select-none">
      <div className="flex gap-5">
        {links.map((link, i) => {
          return (
            <a
              href={link.link}
              key={i}
              rel="noreferrer noopener"
              target="__blank"
              className="transition-opacity duration-500 hover:opacity-80"
            >
              <img className="h-10" src={link.imageUrl} alt={link.title} />
            </a>
          );
        })}
      </div>

      <h1 className="mt-5 font-logo">DIPPIES</h1>
      <p>©︎ All Rights Reserved</p>
    </div>
  );
};
