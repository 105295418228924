import React from "react";
import { Project } from ".";
import { CloseButton } from "../Generic/Button";

interface Props {
  onClose: () => void;
  projectData: Project;
  scrollableRef: any;
  expanded: boolean;
}

export const ExpandedView: React.FC<Props> = ({
  onClose,
  projectData,
  scrollableRef,
  expanded,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full" style={{ zIndex: 999 }}>
      <div className="relative w-full h-full bg-gray-50">
        <CloseButton onClick={onClose} />
        <div className="flex flex-col w-full h-full gap-4 p-6 overflow-hidden md:p-10 md:gap-10 md:flex-row">
          <div className="flex-shrink-0 w-full overflow-hidden rounded-xl md:flex-shrink h-3/5 md:h-full">
            {expanded ? projectData.model : null}
          </div>

          <div
            ref={scrollableRef}
            className="flex flex-col gap-2 pt-6 overflow-y-auto md:gap-6 md:h-full md:max-w-sm"
          >
            <h1 className="text-3xl font-bold">{projectData.projectTitle}</h1>
            <p className="">{projectData.description}</p>

            <ul className="h-full md:overflow-auto">
              {projectData.galleryImages.map((imgUrl, i) => {
                return (
                  <img
                    key={`${projectData.projectTitle}-${i}`}
                    src={imgUrl}
                    alt={`${projectData.projectTitle}-gallery-images`}
                    className="object-cover w-full mb-3 rounded-lg"
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
