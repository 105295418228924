import React from "react";
import { Backdrop } from "../IslandScene";
import { CarouselContainer } from "./CarouselContainer";
import { DaikonScene } from "./daikon/DaikonScene";
import { OngokuScene } from "./ongoku/OngokuScene";
// import { OaktreeScene } from "./oaktree/OaktreeScene";
import { TerasakaScene } from "./terasaka-rice/TerasakaScene";
import { WadoScene } from "./wado-ruin/WadoScene";
import { KamakuraDogScene } from "./kamakura_dog/KamakuraDogScene";

export interface Project {
  projectTitle: string;
  description: string;
  previewUrl: string;
  model: JSX.Element | null;
  galleryImages: string[];
}

const data: Project[] = [
  {
    projectTitle: "大根島 (静岡県南伊豆市)",
    description:
      "伊豆半島の南端・石廊崎は断崖と奇岩が続く絶景ポイント。特に西へ続く海岸は奥石廊崎と呼ばれ、大根や君掛根などの岩島を中心に勇壮な景色が広がる。",
    previewUrl: "works/daikon/preview.jpg",
    model: (
      <Backdrop
        camera={{ position: [0, 7, 12] }}
        controls={{
          limit: { near: 8.5 },
        }}
      >
        <axesHelper />
        <DaikonScene />
      </Backdrop>
    ),
    galleryImages: [
      "works/daikon/preview_1.jpg",
      "works/daikon/preview_2.jpg",
      "works/daikon/preview_3.jpg",
    ],
  },
  {
    projectTitle: "遠国島 (静岡県下田市)",
    description:
      "遠国島はにちょう浜海岸のはずれの海岸部に隣接する小島である。大正6年に発掘調査が行われ、狭い島の頂上から奈良時代の土器が多数発見された。遺跡は人里はなれた急峻な島頂に存在することなどから、海や海島の神々をまつる古代の祭祀遺跡と考えられている。",
    previewUrl: "works/ongoku/preview.jpg", // TODO
    model: (
      <Backdrop
        camera={{ position: [-90, 7, 12], fov: 50 }}
        controls={{
          limit: { near: 8.5 },
        }}
      >
        <OngokuScene />
      </Backdrop>
    ),
    galleryImages: [
      "works/ongoku/ongoku_preview_1.jpg",
      "works/ongoku/ongoku_preview_2.jpg",
      "works/ongoku/ongoku_map_zoomed.jpg",
    ],
  },
  {
    projectTitle: "寺坂棚田 (埼玉県秩父郡)",
    description:
      "武甲山を中心に連なる山々を背景にした絶好のロケーションにあり、初夏の田植えから、初秋には黄金の稲穂、朱色の彼岸花など、 里山の風景が四季を通して楽しめる。",
    previewUrl: "works/terasaka-rice/preview.jpg", // TODO
    model: (
      <Backdrop
        camera={{ position: [0, 20, 30], fov: 50 }}
        scene={{ fog: { far: 120 } }}
        controls={{
          limit: { down: 0.5, near: 5, far: 30, left: 0.7, right: 0.4 },
        }}
      >
        <TerasakaScene />
      </Backdrop>
    ),
    galleryImages: [
      "works/terasaka-rice/terasaka_preview_1.jpg",
      "works/terasaka-rice/terasaka_preview_2.jpg",
      "works/terasaka-rice/terasaka_map_zoomed.jpg",
    ],
  },
  {
    projectTitle: "和銅遺跡 (埼玉県秩父郡)",
    description:
      "西暦708年、武蔵国秩父郡から銅が献上され、これを喜んだ朝廷は年号を「和銅」と改元し、日本最初の貨幣「和同開珎」を発行した。その「和銅」が採掘された跡が、今もなお秩父市黒谷の和銅山に残されている。ここを中心とした一帯が「和銅遺跡」である。",
    previewUrl: "works/wado-ruin/preview.jpg", // TODO
    model: (
      <Backdrop
        camera={{ position: [0, 5, 15] }}
        scene={{ fog: { far: 50 } }}
        controls={{ limit: { far: 20, down: 0.5 } }}
      >
        <WadoScene />
      </Backdrop>
    ),
    galleryImages: [
      "works/wado-ruin/wado_preview_1.jpg",
      "works/wado-ruin/wado_preview_2.jpg",
      "works/wado-ruin/wado_map_zoomed.jpg",
    ],
  },
  // {
  //   projectTitle: "山抱きの大樫 (東京あるきの市)",
  //   description:
  //     "露出した石灰岩の上に根を張って立っている様（さま）が、山全体を抱いているような印象を与えることから、「山抱きの大樫」と呼ばれるようになりました。大樫のある小尾根からは深沢地区の山々と集落を見下ろすことができ、大樫の周りをぐるりと一周できます。",
  //   previewUrl: "works/oaktree/preview.jpg", // TODO
  //   model: (
  //     <Backdrop
  //       camera={{ position: [0, 0, 15] }}
  //       //scene={{ fog: { far: 27 } }}
  //       //controls={{ limit: { far: 20, near: 12, up: 0.3, down: 0.6 } }}
  //       controls={{
  //         limit: { down: 0.5, near: 5, far: 30, left: 0.7 },
  //       }}
  //     >
  //       <OaktreeScene />
  //     </Backdrop>
  //   ),
  //   galleryImages: [
  //     "works/oaktree/oak_tree_preview_1.jpg",
  //     "works/oaktree/oak_tree_preview_2.jpg",
  //     "works/oaktree/oak_tree_map_zoomed.jpg",
  //   ],
  // },

  {
    projectTitle: "鶴岡八幡宮の狛犬 (神奈川県鎌倉市)",
    description:
      "鶴岡八幡宮（神奈川県鎌倉市）の神域を守る狛犬（こまいぬ）。現在は同八幡宮のオリジナルマスクが着けられている。参拝者らに感染予防の注意を促すと共に、新型コロナウイルス終息の祈りを込めた。",
    previewUrl: "works/kamakura_dog/preview.jpg", // TODO
    model: (
      <Backdrop
        camera={{ position: [0, 1, 2], zoom: 2 }}
        pannable
      >
        <KamakuraDogScene />
      </Backdrop>
    ),
    galleryImages: [
      "works/kamakura_dog/kamakura_dog_preview_1.jpg",
      "works/kamakura_dog/kamakura_dog_preview_2.jpg",
      "works/kamakura_dog/kamakura_dog_map_zoomed.jpg",
    ],
  },
 
];

export const Works = () => {
  return (
    <div id="works" className="px-6 py-10 bg-yellow-400 md:py-12">
      <h1 className="mb-5 text-2xl font-bold">活動内容</h1>
      <CarouselContainer data={data} />
    </div>
  );
};
