import { AdaptiveDpr, Environment, Html } from "@react-three/drei";
import React, { Suspense } from "react";
import KamakuraDog from "./KamakuraDog";

export const KamakuraDogScene: React.FC = () => {
    return (
        <group name="island-scene">
            {/* <Stats /> */}
            <Suspense fallback={<Html>Loading...</Html>}>
                <KamakuraDog position={[0, -0.5, 0]} rotation={[0, Math.PI * 0.09, 0]} />
                {/* <Plane
                    position={[0, -4, 0]}
                    args={[300, 300]}
                    rotation={[-Math.PI / 2, 0, 0]}
                >
                    <meshStandardMaterial color="#006400" roughness={0.1} />
                </Plane> */}
                <Environment preset="forest" />
            </Suspense>

            <AdaptiveDpr pixelated />
        </group>
    );
};
