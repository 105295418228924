/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    odm_textured_model001_1: THREE.Mesh;
    odm_textured_model001_2: THREE.Mesh;
    odm_textured_model001_3: THREE.Mesh;
    odm_textured_model001_4: THREE.Mesh;
    odm_textured_model001_5: THREE.Mesh;
    odm_textured_model001_6: THREE.Mesh;
    odm_textured_model001_7: THREE.Mesh;
    odm_textured_model001_8: THREE.Mesh;
    odm_textured_model001_9: THREE.Mesh;
    odm_textured_model001_10: THREE.Mesh;
    odm_textured_model001_11: THREE.Mesh;
    odm_textured_model001_12: THREE.Mesh;
    odm_textured_model001_13: THREE.Mesh;
    odm_textured_model001_14: THREE.Mesh;
    odm_textured_model001_15: THREE.Mesh;
    odm_textured_model001_16: THREE.Mesh;
    odm_textured_model001_17: THREE.Mesh;
    odm_textured_model001_18: THREE.Mesh;
    odm_textured_model001_19: THREE.Mesh;
    odm_textured_model001_20: THREE.Mesh;
  };
  materials: {
    ["material0000.001"]: THREE.MeshStandardMaterial;
    ["material0001.001"]: THREE.MeshStandardMaterial;
    ["material0002.001"]: THREE.MeshStandardMaterial;
    ["material0003.001"]: THREE.MeshStandardMaterial;
    ["material0004.001"]: THREE.MeshStandardMaterial;
    ["material0005.001"]: THREE.MeshStandardMaterial;
    ["material0006.001"]: THREE.MeshStandardMaterial;
    ["material0007.001"]: THREE.MeshStandardMaterial;
    ["material0008.001"]: THREE.MeshStandardMaterial;
    ["material0009.001"]: THREE.MeshStandardMaterial;
    ["material0010.001"]: THREE.MeshStandardMaterial;
    ["material0011.001"]: THREE.MeshStandardMaterial;
    ["material0012.001"]: THREE.MeshStandardMaterial;
    ["material0013.001"]: THREE.MeshStandardMaterial;
    ["material0014.001"]: THREE.MeshStandardMaterial;
    ["material0015.001"]: THREE.MeshStandardMaterial;
    ["material0016.001"]: THREE.MeshStandardMaterial;
    ["material0017.001"]: THREE.MeshStandardMaterial;
    ["material0018.001"]: THREE.MeshStandardMaterial;
    ["material0019.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/works/daikon/daikon.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} scale={0.2} {...props} dispose={null}>
      <group
        position={[0, 45.4748497, 0]}
        scale={[0.1885783, 0.3030199, 0.1885497]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_1.geometry}
          material={materials["material0000.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_2.geometry}
          material={materials["material0001.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_3.geometry}
          material={materials["material0002.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_4.geometry}
          material={materials["material0003.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_5.geometry}
          material={materials["material0004.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_6.geometry}
          material={materials["material0005.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_7.geometry}
          material={materials["material0006.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_8.geometry}
          material={materials["material0007.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_9.geometry}
          material={materials["material0008.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_10.geometry}
          material={materials["material0009.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_11.geometry}
          material={materials["material0010.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_12.geometry}
          material={materials["material0011.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_13.geometry}
          material={materials["material0012.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_14.geometry}
          material={materials["material0013.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_15.geometry}
          material={materials["material0014.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_16.geometry}
          material={materials["material0015.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_17.geometry}
          material={materials["material0016.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_18.geometry}
          material={materials["material0017.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_19.geometry}
          material={materials["material0018.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.odm_textured_model001_20.geometry}
          material={materials["material0019.001"]}
        />
      </group>
    </group>
  );
}
