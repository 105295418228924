import { AdaptiveDpr, Environment, Html, Plane } from "@react-three/drei";
import React, { Suspense } from "react";
import Terasaka from "./Terasaka";

export const TerasakaScene: React.FC = () => {
  return (
    <group name="terasaka-scene">
      {/* <Stats /> */}
      <Suspense fallback={<Html>Loading...</Html>}>
        <Terasaka position={[0, 0, -15]} rotation={[0, Math.PI * 0.05, 0]} />
        <Plane
          position={[0, -6.2, 0]}
          args={[300, 300]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial color="#22432A" roughness={0.8} />
        </Plane>

        <Environment preset="forest" />
      </Suspense>

      <AdaptiveDpr pixelated />
    </group>
  );
};
