import { AdaptiveDpr, Environment, Html, Plane } from "@react-three/drei";
import React, { Suspense } from "react";
import Sea from "../../IslandScene/Sea";
import Daikon from "./Daikon";

export const DaikonScene: React.FC<{ isSea?: boolean }> = ({
  isSea = true,
}) => {
  return (
    <group name="island-scene">
      {/* <Stats /> */}
      <Suspense fallback={<Html>Loading...</Html>}>
        <Daikon position={[-2, 0, 0]} rotation={[0, -Math.PI * 0.75, 0]} />

        {isSea ? (
          <Sea position={[0, 0.8, 0]} />
        ) : (
          <Plane
            position={[0, 0.7, 0]}
            args={[80, 80]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <meshStandardMaterial color="#5eb9db" roughness={0.1} />
          </Plane>
        )}
        <Environment preset="forest" />
      </Suspense>

      <AdaptiveDpr pixelated />
    </group>
  );
};
