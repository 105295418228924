import React from "react";
import { Navbar } from "./components/Navbar";
import { TopSection } from "./components/TopSection";
import { WhatWeDo } from "./components/WhatWeDo";
import { Pricing } from "./components/Pricing";
import { Members } from "./components/Members";
import { ContactUs } from "./components/ContactUs";
import { Works } from "./components/Works";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="w-screen text-gray-800">
      <Navbar />
      <TopSection />
      <WhatWeDo />
      <Pricing />
      <Works />
      <Members />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
