import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as MenuIcon } from "../menu.svg";
import { CloseButton } from "./Generic/Button";

const Logo = () => {
  return (
    <div className="flex items-center">
      <img className="h-14" src="images/logo.png" alt="" />
      <h1 className="text-lg font-logo">DIPPIES</h1>
    </div>
  );
};

const Link = ({
  to,
  label,
  onClick,
}: {
  to: string;
  label: string;
  onClick: () => void;
}) => {
  return (
    <a
      className="px-8 py-2 text-xl font-bold tracking-widest transition-all duration-500 rounded-md hover:font-medium hover:text-gray-50 hover:bg-gray-900"
      href={to}
      onClick={onClick}
    >
      {label}
    </a>
  );
};

const Menu = () => {
  const [open, setOpen] = useState(false);
  const handleClicked = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        className={`fixed z-50 transition-all top-0 left-0 w-full ${
          open ? "h-screen" : "h-0 overflow-hidden pointer-events-none"
        }`}
      >
        <div className="flex flex-col w-full h-full bg-yellow-400">
          <CloseButton onClick={handleClicked} />

          <div className="flex flex-col items-center justify-center h-full gap-2">
            <Link to="#home" label="Top" onClick={handleClicked} />
            <Link to="#whatwedo" label="サービス内容" onClick={handleClicked} />
            <Link to="#pricing" label="料金プラン" onClick={handleClicked} />
            <Link to="#works" label="活動内容" onClick={handleClicked} />
            <Link to="#members" label="メンバー" onClick={handleClicked} />
            <Link to="#contactus" label="問い合わせ" onClick={handleClicked} />
          </div>
        </div>
      </div>

      <button
        className="p-4 font-sans transition-colors hover:text-yellow-500"
        onClick={() => setOpen(true)}
      >
        <MenuIcon className="w-6 h-6 " />
      </button>
    </div>
  );
};

export const Navbar = () => {
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const handleScrollUpdate = () => {
      const pos = window.scrollY;

      if (pos > 500) {
        if (isTop) {
          setIsTop(false);
        }
      } else {
        if (!isTop) {
          setIsTop(true);
        }
      }
    };
    window.addEventListener("scroll", handleScrollUpdate);

    return () => {
      window.removeEventListener("scroll", handleScrollUpdate);
    };
  }, [isTop]);

  return (
    <div
      className={`transition-colors fixed top-0 left-0 flex z-40 select-none items-center justify-between w-full ${
        isTop ? "bg-transparent" : "bg-white shadow-sm"
      }`}
    >
      <Logo />
      <Menu />
    </div>
  );
};
